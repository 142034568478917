<template>
  <section class="blogContent">
    <div v-if="isLoading">
      <Loading />
    </div>
    <div v-else>
      <div class="container blogs-container" v-if="post.id">
        <div class="row">
          <div class="col-md-12 social-share">
            <div>
              <span>{{ parseDate(post.updated_at) }}</span>
              <span class="dot"> . </span>
              <span class="readtime">5 min read</span>
            </div>
            <div class="blogssocial-links">
              <a href="" target="blank" class="twitter">
                <ICON_Twitter
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="blank" class="facebook">
                <ICON_Facebook
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="_blank">
                <ICON_Linkedin
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="_blank">
                <ICON_Mail
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="_blank">
                <ICON_Share
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
            </div>
          </div>
          <div class="col-md-12 mt-4 mb-4">
            <h1 class="mb-2">{{ post.title }}</h1>
            <div class="sub-title">{{ post.description }}</div>
          </div>
          <div class="col-md-12">
            <div v-if="post.cover_url" class="blog-image">
              <img
                v-if="post.cover_url"
                class="serviceimage"
                alt="Images"
                :src="$filters.parseUrl(post.cover_url)"
              />
              <div class="skeleton skeleton-image" v-else></div>
            </div>
          </div>
          <div class="col-md-12 pb-2">
            <p v-html="$filters.markdown(post.body)" class="blog-content"></p>
          </div>
          <hr />
          <div class="row v-items-center">
            <div class="col-md-4">
              <h5>Share this post</h5>
              <div class="blogssocial-links">
                <a href="" target="blank" class="twitter">
                  <ICON_Twitter
                    :size="icon_size"
                    :color="social_icon"
                    class="vicon"
                  />
                </a>
                <a href="" target="blank" class="facebook">
                  <ICON_Facebook
                    :size="icon_size"
                    :color="social_icon"
                    class="vicon"
                  />
                </a>
                <a href="" target="_blank">
                  <ICON_Linkedin
                    :size="icon_size"
                    :color="social_icon"
                    class="vicon"
                  />
                </a>
                <a href="" target="_blank">
                  <ICON_Mail
                    :size="icon_size"
                    :color="social_icon"
                    class="vicon"
                  />
                </a>
                <a href="" target="_blank">
                  <ICON_Share
                    :size="icon_size"
                    :color="social_icon"
                    class="vicon"
                  />
                </a>
              </div>
            </div>
            <div class="col-md-8">
              <div class="d-flex blogtags" v-if="post.tag_line">
                <ICON_Tags :size="tag_size" :color="social_icon" />
                <span style="padding: 0px 10px">{{ post.tag_line }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <RecentPosts />
</template>

<script>
import RecentPosts from "@/components/Blogposts/RecentPosts.vue";
import axios from "axios";
import ICON_Facebook from "@/assets/icons/Facebook.vue";
import ICON_Twitter from "@/assets/icons/Twitter.vue";
import ICON_Linkedin from "@/assets/icons/Linkedin.vue";
import ICON_Tags from "@/assets/icons/Tags.vue";
import ICON_Mail from "@/assets/icons/Mail.vue";
import ICON_Share from "@/assets/icons/Share.vue";
import Loading from "./Loading.vue";
export default {
  components: {
    RecentPosts,
    ICON_Facebook,
    ICON_Twitter,
    ICON_Linkedin,
    ICON_Tags,
    ICON_Mail,
    ICON_Share,
    Loading,
  },
  data() {
    return {
      social_icon: "rgba(168, 168, 168, 1)",
      icon_size: 24,
      tag_size: 18,
      isLoading: false,
      lang: "en",
      slug: this.$router.currentRoute._value.params.slug,
      post: {
        api_handle: "",
        id: "",
        cover_url: "",
        title: "",
        description: "",
        body: "",
        updated_at: "",
      },
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    parseDate(date) {
      return (date + "").split("T")[0];
    },
    ActionUpdateStore() {
      this.isLoading = true;
      let slug = this.$router.currentRoute._value.params.slug;

      const url =
        "https://au.api.vezham.com/widgets/blogs/v1/posts/slug/" + slug;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      axios
        .get(url, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.$router.push({ name: "NotFound" });
            return;
          }
          this.post = data.data;
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.blogContent {
  padding: 120px 30px 30px 30px;
}
.blog-image {
  display: flex;
  align-items: center;
  margin: 0px 0px 25px 0px;
}
img.serviceimage {
  width: 100%;
  height: 85%;
}
.blogs-container {
  max-width: 900px;
}
.blog-content {
  font-size: 18px;
  text-align: justify;
}
@media (max-width: 998px) and (min-width: 0px) {
  .blogContent {
    padding: 30px;
  }
  .social-share {
    flex-direction: column;
    gap: 15px;
  }
  .blogtags {
    margin-top: 20px;
  }
}
.readtime {
  padding: 0px 5px;
}
span.dot {
  position: relative;
  bottom: 5px;
  left: 3px;
}
.blogssocial-links a {
  padding: 10px 10px 10px 0px;
}
.blogtags {
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #dadcdf;
  border-radius: 10px;
  padding: 5px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
}
.social-share {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.sub-title {
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: #5f6368;
}
.v-items-center {
  align-items: center;
}
.vicon {
  fill: gray;
}
.vicon:hover {
  fill: #000 !important;
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.skeleton-image {
  width: 100%;
  height: 20rem;
  margin-bottom: 15px;
}
</style>
