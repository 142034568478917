<template>
  <div class="container blogs-container">
    <div class="row">
      <div class="social-share">
        <div class="skeleton skeleton-heading"></div>
        <div class="skeleton skeleton-heading"></div>
      </div>
      <div class="mt-4">
        <div class="skeleton skeleton-title"></div>
        <div class="skeleton skeleton-content mt-2"></div>
        <div class="skeleton skeleton-content mt-2"></div>
      </div>
      <div class="mt-4">
        <div class="skeleton skeleton-image"></div>
      </div>
      <div>
        <div class="skeleton skeleton-content mt-2"></div>
        <div class="skeleton skeleton-content mt-2"></div>
        <div class="skeleton skeleton-content mt-2"></div>
        <div class="skeleton skeleton-content mt-2"></div>
        <div class="skeleton skeleton-content mt-2"></div>
        <div class="skeleton skeleton-content mt-2"></div>
      </div>
      <hr />
      <div class="row v-items-center">
        <div class="col-md-3">
          <div class="skeleton skeleton-content mt-2"></div>
          <div class="skeleton skeleton-content mt-2"></div>
        </div>
        <div class="col-md-9">
          <div class="tags">
            <div class="skeleton skeleton-tags"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blogContent {
  padding: 120px 30px 30px 30px;
}
.blog-image {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}
.blogs-container {
  max-width: 900px;
}
.blog-content {
  font-size: 18px;
  text-align: justify;
}
.social-share {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
@media (max-width: 998px) and (min-width: 0px) {
  .blogContent {
    padding: 30px;
  }
  .blogtags {
    margin-top: 20px;
  }
  .social-share {
    flex-direction: column;
    gap: 15px;
  }
}
.tags {
  width: 30%;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #dadcdf;
  border-radius: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.v-items-center {
  align-items: center;
}
.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 10px;
}
.skeleton-date {
  width: 30%;
  height: 0.7rem;
  margin-bottom: 15px;
}
.skeleton-heading {
  width: 20%;
  height: 1rem;
  margin-bottom: 15px;
}
.skeleton-image {
  width: 100%;
  height: 20rem;
  margin-bottom: 15px;
}
.skeleton-title {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 15px;
}
.skeleton-content {
  width: 100%;
  height: 1rem;
  margin-bottom: 15px;
}
.skeleton-tags {
  width: 100%;
  height: 1rem;
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
